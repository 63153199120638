import * as Cookies from 'js-cookie';

// Based on cookies_eu.js from cookies_eu gem

$(() => {
//= require js.cookie
// 'use strict';

  const cookiesEu = {
    isCalled: false,
    init() {
      const cookiesEuOKButton = document.querySelector('.js-cookies-eu-ok');

      if (this.isCalled === false) {
        if (cookiesEuOKButton) {
          this.addListener(cookiesEuOKButton);
          this.isCalled = true;
          // console.log('***** OK button listener set');
        }
      }
    },
    addListener(target) {
      // Support for IE < 9
      if (target.attachEvent) {
        target.attachEvent('onclick', this.setCookie);
      } else {
        target.addEventListener('click', this.setCookie, false);
      }
    },
    setCookie() {
      Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365 });
      const container = document.querySelector('.js-cookies-eu');
      container.parentNode.removeChild(container);
    },
  };
  (function () {
    // var timer;
    const isReady = function () {
      cookiesEu.init();
    };
    if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', isReady, false);
    }
    isReady();
    // Old browsers IE < 9
    if (window.addEventListener) {
      window.addEventListener('load', isReady, false);
    } else if (window.attachEvent) {
      window.attachEvent('onload', isReady);
    }
  }());
});
